import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Form,
  FormGroup,
  FormField,
  Input,
  Dropdown,
  Segment,
  Button
} from 'semantic-ui-react'

const CardORCountItemUX = (props: any) => {
    return(
      <div
        id="CardORCountItemUX-div-0"
        style={{ padding: "20px" }}>
        <Form
          id="CardORCountItemUX-Form-1">
          <FormGroup
            id="CardORCountItemUX-FormGroup-2">
            <FormField
              id="CardORCountItemUX-FormField-3"
              width={2}>
              Item
            </FormField>
            <FormField
              id="CardORCountItemUX-FormField-4"
              width={15}>
              <Input
                id="CardORCountItemUX-Input-5"
                readOnly={true}
                readonly={true}
                value={props.itemName}>
              </Input>
            </FormField>
          </FormGroup>
          <FormGroup
            id="CardORCountItemUX-FormGroup-6">
            <FormField
              className="required"
              id="CardORCountItemUX-FormField-7"
              width={2}>
              Reason
            </FormField>
            <FormField
              id="CardORCountItemUX-FormField-8"
              width={15}>
              <Dropdown
                allowAdditions={true}
                clearable={true}
                id="CardORCountItemUX-Dropdown-9"
                onAddItem={props.onAddReasonOptions}
                onChange={props.onChangeReason}
                options={props.reasonOptions}
                search={true}
                selection={true}
                value={props.reason}>
              </Dropdown>
            </FormField>
          </FormGroup>
          <FormGroup
            id="CardORCountItemUX-FormGroup-13">
            <FormField
              id="CardORCountItemUX-FormField-17"
              width={16}>
              <Segment
                id="CardORCountItemUX-Segment-10">
                <label
                  id="CardORCountItemUX-label-20"
                  style={{ fontWeight: "bold" }}>
                  * Note: Delete Item
                </label>
                <div
                  id="CardORCountItemUX-div-11"
                  style={{ paddingTop: "15px" }}>
                  {props.shiftComponent}
                </div>
              </Segment>
            </FormField>
          </FormGroup>
          <FormGroup
            id="CardORCountItemUX-FormGroup-14">
            <FormField
              id="CardORCountItemUX-FormField-15">
              <Button
                color="green"
                id="CardORCountItemUX-Button-16"
                onClick={props.onDelete}>
                Confirm
              </Button>
            </FormField>
            <FormField
              id="CardORCountItemUX-FormField-18">
              <Button
                color="red"
                id="CardORCountItemUX-Button-19"
                onClick={props.onClose}>
                close
              </Button>
            </FormField>
          </FormGroup>
        </Form>
      </div>
    )
}


export default CardORCountItemUX

export const screenPropsDefault = {}

/* Date Time : Tue Mar 11 2025 09:31:03 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "id": {
          "type": "value",
          "value": "CardORCountItemUX-div-0"
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"20px\" }"
        }
      },
      "seq": 0
    },
    {
      "from": "semantic-ui-react",
      "id": 1,
      "name": "Form",
      "parent": 0,
      "props": {
        "id": {
          "type": "value",
          "value": "CardORCountItemUX-Form-1"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 2,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "id": {
          "type": "value",
          "value": "CardORCountItemUX-FormGroup-2"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 3,
      "name": "FormField",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "Item"
        },
        "id": {
          "type": "value",
          "value": "CardORCountItemUX-FormField-3"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 4,
      "name": "FormField",
      "parent": 2,
      "props": {
        "id": {
          "type": "value",
          "value": "CardORCountItemUX-FormField-4"
        },
        "width": {
          "type": "code",
          "value": "15"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 5,
      "name": "Input",
      "parent": 4,
      "props": {
        "id": {
          "type": "value",
          "value": "CardORCountItemUX-Input-5"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "readonly": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.itemName"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "id": {
          "type": "value",
          "value": "CardORCountItemUX-FormGroup-6"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 7,
      "name": "FormField",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": "Reason"
        },
        "className": {
          "type": "value",
          "value": "required"
        },
        "id": {
          "type": "value",
          "value": "CardORCountItemUX-FormField-7"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "FormField",
      "parent": 6,
      "props": {
        "id": {
          "type": "value",
          "value": "CardORCountItemUX-FormField-8"
        },
        "width": {
          "type": "code",
          "value": "15"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 9,
      "name": "Dropdown",
      "parent": 8,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardORCountItemUX-Dropdown-9"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddReasonOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeReason"
        },
        "options": {
          "type": "code",
          "value": "props.reasonOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.reason"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 10,
      "name": "Segment",
      "parent": 17,
      "props": {
        "id": {
          "type": "value",
          "value": "CardORCountItemUX-Segment-10"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "div",
      "parent": 10,
      "props": {
        "children": {
          "type": "code",
          "value": "props.shiftComponent"
        },
        "id": {
          "type": "value",
          "value": "CardORCountItemUX-div-11"
        },
        "style": {
          "type": "code",
          "value": "{ paddingTop: \"15px\" }"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 13,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "id": {
          "type": "value",
          "value": "CardORCountItemUX-FormGroup-13"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 14,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "id": {
          "type": "value",
          "value": "CardORCountItemUX-FormGroup-14"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 15,
      "name": "FormField",
      "parent": 14,
      "props": {
        "id": {
          "type": "value",
          "value": "CardORCountItemUX-FormField-15"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 16,
      "name": "Button",
      "parent": 15,
      "props": {
        "children": {
          "type": "value",
          "value": "Confirm"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "id": {
          "type": "value",
          "value": "CardORCountItemUX-Button-16"
        },
        "onClick": {
          "type": "code",
          "value": "props.onDelete"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 17,
      "name": "FormField",
      "parent": 13,
      "props": {
        "id": {
          "type": "value",
          "value": "CardORCountItemUX-FormField-17"
        },
        "width": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 18,
      "name": "FormField",
      "parent": 14,
      "props": {
        "id": {
          "type": "value",
          "value": "CardORCountItemUX-FormField-18"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 19,
      "name": "Button",
      "parent": 18,
      "props": {
        "children": {
          "type": "value",
          "value": "close"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "id": {
          "type": "value",
          "value": "CardORCountItemUX-Button-19"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClose"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "label",
      "parent": 10,
      "props": {
        "children": {
          "type": "value",
          "value": "* Note: Delete Item"
        },
        "id": {
          "type": "value",
          "value": "CardORCountItemUX-label-20"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\" }"
        }
      },
      "seq": 11,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "name": "CardORCountItemUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
